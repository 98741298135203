<template>
  <div>
    <!-- <a-button  style="margin-left: 8px" icon="reload"  @click="testtttt" >测试跳转</a-button> -->
    <div class="title">充值管理</div>
    <div class="header"><span></span> 账户信息</div>
    <div class="nav">
      <div class="nav-content">
        <!-- <div>账户余额：</div>
        <div class="account" v-if="accountInfo">￥{{accountInfo.normalBalanceAmount}}</div>
        <a-button type="primary" @click="showHkxx">充值</a-button> -->

        <div style="width: 30%; text-align: center">
          <a-statistic
            title="银行卡账户(元)"
            :precision="2"
            :value="accountInfo.normalBalanceAmount"
          />
          <span class="transfer_account" @click="showHkxx">转入</span>
        </div>
        <a-divider type="vertical" />
        <div style="width: 30%; text-align: center">
          <a-statistic title="支付宝账户(元)" value=" " v-if="!isSignAliapy" />
          <a-statistic
            title="支付宝账户(元)"
            :precision="2"
            :value="alipayAccount"
            v-if="isSignAliapy"
          />
          <span
            class="transfer_account"
            v-if="!isSignAliapy"
            @click="visibleSignAlipay = true"
            >开通</span
          >
          <span
            class="transfer_account"
            v-if="isSignAliapy"
            @click="visibleAlipay = true"
            >转入</span
          >
        </div>
        <a-divider type="vertical" />
        <div style="width: 30%; text-align: center">
          <a-statistic title="微信账户(元)" value="未开通" />
        </div>
      </div>
    </div>
    <div class="header"><span></span> 充值记录</div>
    <!-- 搜索 -->
    <div class="search">
      <span>充值时间：</span>
      <a-range-picker v-model="date" @change="onChange" />
      <div class="table-search-submitButtons">
        <a-button type="primary" @click="handleToSearchEnterprise"
          >查询</a-button
        >
        <a-button
          style="margin-left: 8px"
          icon="reload"
          @click="resetSearchEnterprise"
          >重置</a-button
        >
        <a-button
          style="margin-left: 8px"
          type="primary"
          @click="handleToExprotRecharges('充值记录')"
          >导出</a-button
        >
      </div>
    </div>
    <div class="content">
      <s-table
        style="border-top: 1px solid #f0f0f0; margin-top: 16px"
        ref="raskTable"
        :columns="raskColumns"
        :data="raskData"
        @change="onRaskChange"
        :rowKey="(record) => record.id"
      >
      </s-table>
    </div>
    <!-- 付款信息 -->
    <a-modal
      title="汇款信息"
      :visible="visiblehk"
      @ok="visiblehk = false"
      @cancel="visiblehk = false"
      width="700px"
    >
      <div>
        <div class="hkxx-header">
          <a-icon
            theme="filled"
            style="color: #1890ff; margin-right: 4px"
            type="info-circle"
          />温馨提示:请通过网银线下转款到以下账户，并填写完善相关信息。该账户为银行监管账户，您的转款到账后会立即转入至指定名单
        </div>
        <ul class="hkxx-content">
          <li>
            <span class="left">付款账户</span>
            <span class="right">{{ payEnterpriseName || "" }}</span>
          </li>
          <li>
            <span class="left">收款账户名</span>
            <span class="right">{{ bankInfo.accountName }}</span>
          </li>
          <li>
            <span class="left">开户银行</span>
            <span class="right">{{ bankInfo.bankFullName }}</span>
          </li>
          <li>
            <span class="left">银行卡号</span>
            <span class="right">{{ bankInfo.bankNo }}</span>
          </li>
        </ul>
      </div>
    </a-modal>

    <!-- 支付宝开通 -->
    <a-modal
      title="支付宝开通"
      :visible="visibleSignAlipay"
      @ok="visibleSignAlipay = false"
      @cancel="visibleSignAlipay = false"
      width="800px"
    >
      <div class="alipay_open_tips">
        <div class="tips_container">
          <div class="step">
            <div class="step_icon">1</div>
            <div class="step_line"></div>
            <div class="step_icon">2</div>
          </div>
          <div class="tips_box">
            <div class="text_tips padd_bot_50">
              <div class="tips">企业支付宝注册</div>
              <div class="tips_a">
                请使用 <b>{{ enterpriseRealName }}</b> 资料， 点击
                <span @click="alipayReg">企业支付宝注册</span>
                根据提示提交资料完成注册。
              </div>
            </div>

            <div class="text_tips">
              <div class="tips">支付宝扫码签约</div>
              <div class="tips_a">
                注册完成后请使用 <b>{{ enterpriseRealName }}</b> 支付宝APP，点击
                <span @click="singAlipay">扫码绑定</span> 完成绑定。
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <!-- 支付宝转入 -->
    <a-modal
      title="支付宝转入"
      :visible="visibleAlipay"
      @ok="alipayRecharge"
      @cancel="visibleAlipay = false"
      width="800px"
      destroyOnClose
      okText="转入"
    >
      <div>
        <div class="img_tips">
          <div>
            <img src="../../assets/tips_icon.png" alt="注意" />
          </div>
          <div>
            <div style="padding-bottom:5px,color:#595959">
              点击“转入”按钮后会跳转到支付宝页面，请使用
              <span style="font-weight: bold">{{ enterpriseRealName }}</span>
              企业支付宝账户进行登录充值，
            </div>
            <div style="color: '#595959'">
              付款成功后请勿重复转入，如遇到问题请联系我们或拨打400-996-2055
            </div>
          </div>
        </div>
        <div class="alipay_recharge">
          <span style="color: #262626">转入金额：</span
          ><a-input
            placeholder="请输入转入金额"
            type="number"
            v-model="alipayRechargeIpt"
            style="width: 300px"
          ></a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import STable from "@/components/table/";
import Vue from "vue";
import {
  getRecharges,
  exprotRecharges,
  getBanknum,
  getRechargesAccount,
  getEnterprisePayConfig,
  singAlipay,
  queryAlipay,
  alipayRecharge,
  updateAlipayRecharge,
  getRechargesAccountAlipay,
} from "@/api/api";
import moment from "moment";
export default {
  components: {
    STable,
  },
  data() {
    return {
      raskColumns: [
        {
          title: "主键ID",
          dataIndex: "id",
        },
        {
          title: "充值金额",
          dataIndex: "rechargeAmount",
          customRender: (text) => text.toFixed(2),
        },
        {
          title: "钱包类型",
          dataIndex: "walletType",
          customRender: (text) =>
            text === "100401"
              ? "银行账户"
              : text === "100402"
              ? "支付宝账户"
              : "微信账户",
        },
        {
          title: "充值类型",
          dataIndex: "rechargeType",
          customRender: (text) => (text === "100901" ? "线上充值" : "线下充值"),
        },
        {
          title: "充值状态",
          dataIndex: "status",
          customRender: (text) =>
            text === "101001"
              ? "充值中"
              : text === "101011"
              ? "充值成功"
              : "充值失败",
        },
        {
          title: "充值时间",
          dataIndex: "rechargeTime",
        },
      ],
      pagenation: {
        showSizeChanger: true,
        pageNo: 1,
        pageSize: 10,
      },
      date: [],
      queryParams: {
        rechargeTime_begin: undefined,
        rechargeTime_end: undefined,
      },
      visiblehk: false,
      bankInfo: {},
      accountInfo: "",
      payEnterpriseName: "",

      isSignAliapy: false,
      alipayAccount: "0",
      enterpriseRealName: "",
      visibleAlipay: false,
      alipayRechargeIpt: "",
      visibleSignAlipay: false,
    };
  },
  created() {
    this.getRechargesAccount();
    this.queryAlipay();
    if (this.getUrlSearch("id")) {
      this.updateAlipayRecharge(this.getUrlSearch("id"));
    }
    this.enterpriseRealName = Vue.ls.get("Login_Userinfo").realname;
    if (this.$route.params.isShowModal === "tips") {
      this.$nextTick(() => {
        this.showHkxx(); //工商银行显示
      });
    }
  },
  methods: {
    testtttt() {
      window.open(
        "http://zfycloud.mynatapp.cc/#/rechargeManage?id=1412654895940018177"
      );
    },
    //查询充值状态
    updateAlipayRecharge(id) {
      updateAlipayRecharge({ outBizNo: id }).then((res) => {
        if (res.success) {
          this.$message.success("充值成功");
          // window.location.replace('/rechargeManage')
          this.queryAlipay();
          // window.location.reload();
        }
      });
    },
    getUrlSearch(name) {
      // 未传参，返回空
      if (!name) return null;
      // 查询参数：先通过search取值，如果取不到就通过hash来取
      var after = window.location.search;
      after = after.substr(1) || window.location.hash.split("?")[1];
      // 地址栏URL没有查询参数，返回空
      if (!after) return null;
      // 如果查询参数中没有"name"，返回空
      if (after.indexOf(name) === -1) return null;

      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      // 当地址栏参数存在中文时，需要解码，不然会乱码
      var r = decodeURI(after).match(reg);
      // 如果url中"name"没有值，返回空
      if (!r) return null;

      return r[2];
    },
    //支付宝注册
    alipayReg() {
      window.open("https://certifyweb.alipay.com/certify/reg/guide#/");
    },
    //支付宝充值
    alipayRecharge() {
      if (!this.alipayRechargeIpt) {
        this.$message.info("请输入转入金额");
        return;
      }
      if (this.alipayRechargeIpt < 0 || this.alipayRechargeIpt == 0) {
        this.$message.info("转入金额需大于0");
        return;
      }

      alipayRecharge({ transAmount: this.alipayRechargeIpt, tag: "2" }).then(
        (res) => {
          if (res.success) {
            this.alipayRechargeIpt = "";
            this.visibleAlipay = false;
            this.$confirm({
              icon: " ",
              title: "请选择支付结果",
              content: "如有其他问题请联系客户经理或拨打400-996-2055",
              okText: "付款成功",
              cancelText: "取消",
              onOk() {
                return new Promise((resolve, reject) => {
                  window.location.reload();
                  resolve();
                }).catch(() => console.log("Oops errors!"));
              },
            });
            window.open(res.result.url);
          }
        }
      );
    },
    //查询支付宝余额
    getAlipayAccount() {
      getRechargesAccountAlipay().then((res) => {
        if (res.success && res.result) {
          this.alipayAccount = res.result.normalBalanceAmount;
        }
      });
    },
    //查询是否开通支付宝
    queryAlipay() {
      queryAlipay().then((res) => {
        if (res.success) {
          let resInfo = res.result;
          if (!resInfo) {
            //没有数据 未开通
            this.isSignAliapy = false;
          } else {
            //有数据 判断状态
            if (resInfo.status == "100202") {
              //未开通
              this.isSignAliapy = false;
            }
            if (resInfo.status == "100201") {
              //已开通
              this.isSignAliapy = true;
              //查询支付宝账户余额
              this.getAlipayAccount("alipay");
            }
          }
        }
      });
    },
    //支付宝签约
    singAlipay() {
      singAlipay().then((res) => {
        if (res.success) {
          window.open(res.result.url);
        } else {
          this.$message.info(res.message);
        }
      });
    },
    //获取收款账号
    getPayConfig() {
      getEnterprisePayConfig("103101").then((res) => {
        if (res.success && res.result) {
          this.bankInfo = res.result;
        }
      });
    },
    // 选择时间
    onChange(date, dateString) {
      this.queryParams.rechargeTime_begin = dateString[0];
      this.queryParams.rechargeTime_end = dateString[1];
    },
    // 获取银行卡号
    getBanknum() {
      getBanknum().then((res) => {
        if (res.success && res.result) {
          // this.bankInfo = res.result
          this.payEnterpriseName = res.result.payEnterpriseName;
        }
      });
    },
    // 获取账户余额
    getRechargesAccount() {
      getRechargesAccount().then((res) => {
        if (res.success) {
          this.accountInfo = res.result;
        }
      });
    },
    // 充值
    showHkxx() {
      this.getBanknum();
      this.getPayConfig();
      this.visiblehk = true;
    },
    // 获取订单列表
    raskData(parameter) {
      return getRecharges(Object.assign(parameter, this.queryParams)).then(
        (res) => {
          // res.result.data = res.result.records
          return res.result;
        }
      );
    },
    // 查询
    handleToSearchEnterprise() {
      this.$refs.raskTable.refresh(true);
    },
    // 导出
    handleToExprotRecharges(fileName) {
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = {
        ...this.queryParam,
        // pageNo:this.tablePageNo,
      };

      exprotRecharges(param).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        } else {
          this.$message.success("导出成功");
        }

        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(new Blob([data]), fileName + ".xls");
        } else {
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement("a");
          console.log(link);
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParams = {
        rechargeTime_begin: undefined,
        rechargeTime_end: undefined,
      };
      this.date = [];
      this.handleToSearchEnterprise();
    },
    // 表格改变
    onRaskChange(pagination, filters, sorter) {
      // console.log('params', pagination, filters, sorter)
    },
    // 审核
    cancelRask(record) {},
    // 复核
    fuheRask(record) {},
    // 详情
    raskDetail(record) {
      window.localStorage.setItem("submenu", "/settlementDetail");
      window.localStorage.setItem("taskInfo", JSON.stringify(record));
      this.$router.push("/settlementDetail");
    },
  },
};
</script>

<style lang="less" scoped>
.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0;
}
.alipay_open_tips {
  .tips_container {
    display: flex;
    .step {
      margin-right: 25px;
      .step_icon {
        display: inline-block;
        width: 31px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        border-radius: 50%;
        background-color: #1890ff;
        color: #fff;
      }
      .step_line {
        height: 70px;
        width: 1px;
        background-color: #e8e8e8;
        position: relative;
        left: 15px;
        top: 0;
      }
    }
    .text_tips {
      .tips {
        font-size: 14px;
        color: #2e1515;
        font-weight: bold;
        padding-bottom: 5px;
      }
      .tips_a {
        font-size: 14px;
        color: #595959;
        span {
          color: #1890ff;
          border-bottom: 1px solid #1890ff;
          cursor: pointer;
        }
      }
    }
  }
}
.padd_bot_50 {
  padding-bottom: 50px;
}

.img_tips {
  display: flex;
}
.alipay_recharge {
  display: flex;
  align-items: center;
  padding-top: 30px;
  span {
    width: 75px;
  }
}

.ant-divider,
.ant-divider-vertical {
  height: 52px;
}

.transfer_account {
  color: #1890ff;
  cursor: pointer;
}
.header {
  display: flex;
  align-items: center;
  span {
    width: 2px;
    height: 14px;
    background: #347cf5;
    margin-right: 8px;
  }
}
.nav {
  display: flex;
  flex-direction: column;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 0px;
  border: 1px solid #e8e8e8;
  margin: 30px 0;
  padding: 20px;
  align-items: center;
  justify-content: center;
  // background:#f6ffed;
  .nav-content {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .account {
      font-weight: bold;
      font-size: 18px;
      margin: 10px 50px 10px 00px;
    }
  }
}
.hkxx-header {
  color: #333333;
  font-size: 14px;
  margin-bottom: 24px;
  line-height: 24px;
}
.hkxx-content {
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  li {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000000;
    .left {
      width: 200px;
      height: 56px;
      padding: 19px 24px;
      border-top: 1px solid #f0f0f0;
      border-left: 1px solid #f0f0f0;
    }
    .right {
      width: 466px;
      height: 56px;
      padding: 19px 24px;
      border-top: 1px solid #f0f0f0;
      border-left: 1px solid #f0f0f0;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.title {
  font-size: 24px;
  color: #202020;
  margin-bottom: 26px;
}
.search {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.table-search-submitButtons {
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 30px;
}
.table-point {
  display: flex;
  align-items: center;
  // justify-content: center;
  span {
    width: 4px;
    height: 4px;
    background: #1890ff;
    border-radius: 50%;
    margin-right: 4px;
  }
}
</style>
